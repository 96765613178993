<script>
  import { onMount } from "svelte";
  import { goto } from "@sveltech/routify";
  import TransitionWrapper from "../../_components/other/TransitionWrapper.svelte";
  import Header from "../../_components/1_level/Header.svelte";
  import Metadata from "../../_components/other/Metadata.svelte";
  import BlogPosts from "../../_components/1_level/BlogPosts.svelte";
  import Footer from "../../_components/1_level/Footer.svelte";
  import BlogSidebar from "../../_components/1_level/BlogSidebar.svelte";
  import isDataLoaded from "../../_tools/DataLoaded.js";

  const metadata = {};

  export let data = [];
  export let loading = true;

  async function fetchPosts() {
    const response = await fetch(`https://api.breakwater.ee/api/blog`);

    if (response.status === 200) {
      data = await response.json();
      if (!!data.seo) {
        metadata.title = data.seo.meta_title;
        metadata.description = data.seo.meta_description;
      }
      loading = false;
      return { data };
    }
    // If error redirect to 404 page
    console.error(response.status, "Request failed");
    $goto("/");
  }
  onMount(async () => {
    fetchPosts();
  });
</script>

<Metadata {metadata} />

<TransitionWrapper>
  {#if isDataLoaded(data.header)}
    <Header isHomepage={false} headerData={data.header} />
  {/if}
  {#if !loading && !!data}
    <div class="container-fluid">
      <div class="container">
        <div class="row py-4 px-0">
          <div class="col-md-8 col-lg-9">
            <BlogPosts posts={data.items} parentUrl="/blog" />
          </div>
          <div class="col-md-4 col-lg-3">
            <BlogSidebar {data} />
          </div>
        </div>
      </div>
    </div>
  {/if}
  {#if isDataLoaded(data.footer)}
    <Footer footerData={data.footer} />
  {/if}
</TransitionWrapper>
